import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class PMSReportService {

    static getPMSReportByPharmacyId(id, params) {
        return axios.get(`${URL.PMS_GET_REPORT_BY_PHARMACY_ID}/${id}`, {
            params, ...PMSReportService.getAuthHeader()
        });
    }

    static generatePMSExcelReportByPharmacyId(pharmacyId, params) {

        return axios({
            url: `${URL.PMS_GENERATE_EXCEL_REPORT_BY_PHARMACY_ID}/${pharmacyId}`,
            params,
            method: 'GET',
            responseType: 'blob', // Important,
            ...PMSReportService.getAuthHeaderForFileDownload()
        });
    }

    static getPMSAccountBalanceReport(pharmacyId) {
        return axios.get(`${URL.PMS_GET_ACCOUNT_BALANCE_REPORT}/${pharmacyId}`, PMSReportService.getAuthHeader());
    }

    static getPharmacyReportByCompany(pharmacyId, params) {
        return axios.get(`${URL.GET_COMPANY_REPORT}/${pharmacyId}`,
            {
                params, ...PMSReportService.getAuthHeader()
            }
        );
    }

    static getPharmacyGraphReportByCompany(pharmacyId, params) {
        return axios.get(`${URL.GET_COMPANY_GRAPH_REPORT}/${pharmacyId}`,
            {
                params, ...PMSReportService.getAuthHeader()
            }
        );
    }

    static getPharmacyTopLessReportByCompany(pharmacyId, params) {
        return axios.get(`${URL.GET_COMPANY_TOP_LESS_REPORT}/${pharmacyId}`,
            {
                params, ...PMSReportService.getAuthHeader()
            }
        );
    }

    static getPMSDashboardTopData(pharmacyId) {
        return axios.get(`${URL.GET_PMS_DASHBOARD_TOP_DATA}/${pharmacyId}`, PMSReportService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

    static getAllJournalByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_JOURNAL_BY_SHOP_ID}/${id}`, {
            ...PMSReportService.getAuthHeader(),
            params
        })
    }

    static getAuthHeaderForFileDownload = () => {
        const accessToken = localStorage.getItem("oushodsheba_access_token");
        return {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                'Response-Type': 'blob'
            }
        };
    }
}