import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AppContextProvider from "./contexts/AppContextProvider";
import AuthContextProvider from "./contexts/AuthContextProvider";
import ProductContextProvider from "./contexts/ProductContextProvider";
import CustomerContextProvider from "./contexts/CustomerContextProvider";
import UserContextProvider from "./contexts/UserContextProvider";
import OrderContextProvider from "./contexts/OrderContextProvider";
import CartContextProvider from "./contexts/CartContextProvider";
import RoleContextProvider from "./contexts/RoleContextProvider";
import PharmacyContextProvider from "./contexts/PharmacyContextProvider";
import ProfitSharingPlanContextProvider from "./contexts/ProfitSharingPlanContextProvider";
import CompanyContextProvider from "./contexts/CompanyContextProvider";
import ItemContextProvider from "./contexts/ItemContextProvider";
import GenericContextProvider from "./contexts/GenericContextProvider";
import ProductCategoryContextProvider from "./contexts/ProductCategoryContextProvider";
import DrugContextProvider from "./contexts/DrugContextProvider";
import CountryContextProvider from "./contexts/CountryContextProvider";
import HospitalContextProvider from "./contexts/HospitalContextProvider";
import PrescriptionContextProvider from "./contexts/PrescriptionContextProvider";
import DeliveryPersonContextProvider from "./contexts/DeliveryPersonContextProvider";
import PharmacistContextProvider from "./contexts/PharmacistContextProvider";
import CustomerGroupContextProvider from "./contexts/CustomerGroupContextProvider";
import PromoContextProvider from "./contexts/PromoContextProvider";
import ThanaContextProvider from "./contexts/ThanaContextProvider";
import DistrictContextProvider from "./contexts/DistrictContextProvider";
import NotificationContextProvider from "./contexts/NotificationContextProvider";
import DashboardContextProvider from "./contexts/DashboardContextProvider";
import TableContextProvider from "./contexts/ui_configuration_context/TableContextProvider";
import SubscriptionPlanContextProvider from "./contexts/SubscriptionPlanContextProvider";
import PharmacyOwnerContextProvider from "./contexts/PharmacyOwnerContextProvider";
import DeliveryChargeContextProvider from "./contexts/DeliveryChargeContextProvider";
import PharmacyOrderAccountContext from "./contexts/PharmacyOrderAccountContextProvider";
import AreaContextProvider from "./contexts/AreaContextProvider";
import EventContextProvider from "./contexts/EventContextProvider";
import OfferContextProvider from "./contexts/OfferContextProvider";
import InvoiceContextProvider from "./contexts/InvoiceContextProvider";
import BillContextProvider from "./contexts/BillContextProvider";
import ReviewContextProvider from "./contexts/ReviewContextProvider";
import TagContextProvider from "./contexts/TagContextProvider";
import GlobalConfigContextProvider from "./contexts/GlobalConfigContextProvider";
import PregnancyCategoryContextProvider from "./contexts/PregnancyCategoryContextProvider";

/* SCSS */
import "antd/dist/antd.css";
import "./assets/scss/style.scss";
import TemporaryOrderContextProvider from "./contexts/TemporaryOrderContextProvider";
import OrderRoutingScheduleContextProvider from "./contexts/OrderRoutingScheduleContextProvider";
import PermissionContextProvider from "./contexts/PermissionContextProvider";
import DeliveryPlanContextProvider from "./contexts/DeliveryPlanContextProvider";
import PMSCustomerContextProvider from "./contexts/pms_contexts/PMSCustomerContextProvider";
import PMSExpenseContextProvider from "./contexts/pms_contexts/PMSExpenseContextProvider";
import PMSInventoryContextProvider from "./contexts/pms_contexts/PMSInventoryContextProvider";
import PMSPaymentContextProvider from "./contexts/pms_contexts/PMSPaymentContextProvider";
import PMSProductContextProvider from "./contexts/pms_contexts/PMSProductContextProvider";
import PMSReportContextProvider from "./contexts/pms_contexts/PMSReportContextProvider";
import PMSSaleContextProvider from "./contexts/pms_contexts/PMSSaleContextProvider";
import PMSVendorContextProvider from "./contexts/pms_contexts/PMSVendorContextProvider";
import PMSPharmacyContextProvider from "./contexts/pms_contexts/PMSPharmacyContext";
import PMSPharmacistContextProvider from "./contexts/pms_contexts/PMSPharmacistContextProvider";
import PMSExpiredItemsContextProvider from "./contexts/pms_contexts/PMSExpiredItemsContextProvider";
import PMSTransactionAccountContext from "./contexts/pms_contexts/PMSTransactionAccountContextProvider";
import PMSTransactionContextProvider from "./contexts/pms_contexts/PMSTransactionContextProvider";
import PMSCustomerPaymentContextProvider from "./contexts/pms_contexts/PMSCustomerPaymentContextProvider";
import PMSVendorPaymentContextProvider from "./contexts/pms_contexts/PMSVendorPaymentContextProvider";
import PMSCashReceivedContextProvider from "./contexts/pms_contexts/PMSCashReceivedContextProvider";
import LabTestContextProvider from "./contexts/LabTestContextProvider";
import UploadProductFilesContextProvider from "./contexts/UploadProductFilesContextProvider";
import PMSRequisitionsContextProvider from "./contexts/pms_contexts/PMSRequisitionsContextProvider";
import OfferDetailsContextProvider from "./contexts/OfferDetailsContextProvider";
import ReferralHistoryContextProvider from "./contexts/ReferralHistoryContextProvider";
import HomepageProductContextProvider from "./contexts/HomepageProductContextProvider";
import BannerContextProvider from "./contexts/BannerContextProvider";
import PMSOfflineConfigContextProvider from "./contexts/PMSOfflineConfigContextProvider";
import PMSReconciliationContextProvider from "./contexts/pms_contexts/PMSReconciliationContextProvider";
import PMSConfigContextProvider from "./contexts/pms_contexts/PMSConfigContextProvider";
import ThemeContextProvider from "./contexts/ThemeContextProvider";
import PMSInventoryContextProvider2 from "./contexts/pms_contexts/PMSInventoryContextProvider2";
import PMSInventoryContextProvider3 from "./contexts/pms_contexts/PMSInventoryContextProvider3";
import PMSInventoryContextProvider4 from "./contexts/pms_contexts/PMSInventoryContextProvider4";
import ProductTypeContextProvider from "./contexts/ProductTypeContextProvider";

export const contextProviders = [
    AppContextProvider,
    AuthContextProvider,
    ProductContextProvider,
    CustomerContextProvider,
    UserContextProvider,
    OrderContextProvider,
    CartContextProvider,
    RoleContextProvider,
    PharmacyContextProvider,
    ProfitSharingPlanContextProvider,
    CompanyContextProvider,
    ItemContextProvider,
    GenericContextProvider,
    ProductCategoryContextProvider,
    DrugContextProvider,
    CountryContextProvider,
    HospitalContextProvider,
    PrescriptionContextProvider,
    DeliveryPersonContextProvider,
    PharmacistContextProvider,
    CustomerGroupContextProvider,
    PromoContextProvider,
    ThanaContextProvider,
    DistrictContextProvider,
    NotificationContextProvider,
    DashboardContextProvider,
    TableContextProvider,
    SubscriptionPlanContextProvider,
    PharmacyOwnerContextProvider,
    DeliveryChargeContextProvider,
    PharmacyOrderAccountContext,
    AreaContextProvider,
    EventContextProvider,
    OfferContextProvider,
    InvoiceContextProvider,
    BillContextProvider,
    ReviewContextProvider,
    TagContextProvider,
    GlobalConfigContextProvider,
    PregnancyCategoryContextProvider,
    TemporaryOrderContextProvider,
    OrderRoutingScheduleContextProvider,
    PermissionContextProvider,
    DeliveryPlanContextProvider,
    PMSCustomerContextProvider,
    PMSExpenseContextProvider,
    PMSInventoryContextProvider,
    PMSInventoryContextProvider4,
    PMSInventoryContextProvider2,
    PMSPaymentContextProvider,
    PMSProductContextProvider,
    PMSInventoryContextProvider3,
    PMSReportContextProvider,
    PMSSaleContextProvider,
    PMSVendorContextProvider,
    PMSPharmacyContextProvider,
    PMSPharmacistContextProvider,
    PMSExpiredItemsContextProvider,
    PMSTransactionAccountContext,
    PMSTransactionContextProvider,
    PMSCustomerPaymentContextProvider,
    PMSVendorPaymentContextProvider,
    PMSCashReceivedContextProvider,
    LabTestContextProvider,
    UploadProductFilesContextProvider,
    PMSRequisitionsContextProvider,
    OfferDetailsContextProvider,
    ReferralHistoryContextProvider,
    HomepageProductContextProvider,
    BannerContextProvider,
    PMSOfflineConfigContextProvider,
    PMSReconciliationContextProvider,
    PMSConfigContextProvider,
    ThemeContextProvider,
    ProductTypeContextProvider
];

const getAppWithContextProviders = () => {
    let result = <App/>;
    contextProviders.forEach(
        (Provider) => (result = <Provider>{result}</Provider>)
    );

    return result;
};

ReactDOM.render(getAppWithContextProviders(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
