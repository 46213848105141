import React, {createContext, useState} from "react";
import PMSProductServices from "../../services/pms_services/PMSProductServices";
import {decryption} from "../../helpers/Utils";
import Notification from "../../components/common/Notification";

export const PMSProductContext = createContext("PMSProductContext");

const PMSProductContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [searchProducts, setSearchProducts] = useState([]);
    const [loadingProductSearch, setLoadingProductSearch] = useState(false);
    const [pmsCompanyList, setPMSCompanyList] = useState([]);
    const [pagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"],
    });

    const [totalElements, setTotalElements] = useState(0);
    const [products, setProducts] = useState([]);
    const [publicProducts, setPublicProducts] = useState(null);
    const [product, setProduct] = useState(null);

    const [requisitionCart, setRequisitionCart] = useState([]);

    const [totalCompanyProductElements, setTotalCompanyProductElements] = useState(0);
    const [companyProducts, setCompanyProducts] = useState([]);

    async function getAllProductsFromOS(params) {
        try {
            setLoadingProductSearch(true);
            setErrorMsg("");

            const res = await PMSProductServices.getAllProductsFromOS(params);

            const data = decryption(res.data.content);

            setSearchProducts(JSON.parse(data));
            setLoadingProductSearch(false);
        } catch (error) {
            setLoadingProductSearch(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllProductByCompanyId = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PMSProductServices.getAllProductByCompanyId(params);

            const data = decryption(res.data.content);

            setTotalCompanyProductElements(res.data.totalElements);

            setCompanyProducts(JSON.parse(data));

            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllPMSCompany = async (id, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSProductServices.getAllPMSCompany(id, params);
            setPMSCompanyList(res.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function getAllRequiredProductsByPharmacyId() {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSProductServices.getAllRequiredProductsByPharmacyId();

            setProducts(res.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllProductsByPharmacyId = async (id, params) => {

        if (!params.isFortyPercent) {
            delete params.isFortyPercent;
        }

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSProductServices.getAllProductsByPharmacyId(id, params);
            setProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    };

    const getProductByPharmacyIdById = async (pharmacyId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSProductServices.getProductByPharmacyIdById(
                pharmacyId,
                id
            );
            setProduct(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getAllPublicProducts = async ({
                                            page,
                                            size,
                                            categorySlug,
                                            tagTitle,
                                        }) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PMSProductServices.getAllPublicProducts(
                page,
                size,
                categorySlug,
                tagTitle
            );
            setPublicProducts(res.data.content);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const addOrRemoveRequisitionCart = async (data) => {
        const _data = requisitionCart.find((e) => data.product.id === e.product.id);

        if (_data) {
            setRequisitionCart([
                ...requisitionCart.filter((e) => e.product.id !== data.product.id),
            ]);
            return;
        }
        setRequisitionCart([...requisitionCart, data]);
    };

    const existsInRequisitionCart = (id) => {
        const data = requisitionCart.find((e) => e.product.id === id);
        return data != null;
    };

    const changeRequisitionCart = (id, quantity) => {
        setRequisitionCart([]);
        if (isNaN(quantity)) {
            return;
        }
        setRequisitionCart([
            ...requisitionCart.map((e) => {
                if (e.product.id === id) {
                    e.product.quantity = quantity;
                }
                return e;
            }),
        ]);
    };

    const addProduct = async (pharmacyId, data) => {
        try {

            setLoading(true);
            await PMSProductServices.addProduct(pharmacyId, data);
            Notification("success", "Product Created", "Successfully product created");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const updateProduct = async (pharmacyId, id, data) => {
        try {

            setLoading(true);
            await PMSProductServices.updateProduct(pharmacyId, id, data);
            Notification("success", "Product Updated", "Successfully product updated");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const deletePMSProduct = async (pharmacyId, id) => {
        try {

            setLoading(true);
            await PMSProductServices.deletePMSProduct(pharmacyId, id);
            Notification("success", "Product Deleted", "Successfully product deleted");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getProductByBarcode = async (barcode, pharmacyId) => {

        try {
            return await PMSProductServices.getProductByBarcode(barcode, pharmacyId);
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return null;
        }

    }

    const getProductExistsByBarcode = async (barcode, pharmacyId) => {

        try {
            return await PMSProductServices.getProductExistsByBarcode(barcode, pharmacyId);
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return null;
        }

    }

    return (
        <PMSProductContext.Provider
            value={{
                loading,
                loadingProductSearch,
                errorMsg,
                pagination,
                totalElements,
                totalCompanyProductElements,
                products,
                product,
                publicProducts,
                searchProducts,
                companyProducts,
                pmsCompanyList,
                getAllProductsByPharmacyId,
                getProductByPharmacyIdById,
                getAllPublicProducts,
                getAllProductsFromOS,
                getAllRequiredProductsByPharmacyId,
                addOrRemoveRequisitionCart,
                existsInRequisitionCart,
                changeRequisitionCart,
                requisitionCart,
                getAllProductByCompanyId,
                updateProduct,
                addProduct,
                getAllPMSCompany,
                deletePMSProduct,
                getProductByBarcode,
                getProductExistsByBarcode
            }}
        >
            {children}
        </PMSProductContext.Provider>
    );
};

export default PMSProductContextProvider;
