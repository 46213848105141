import * as URL from "../../helpers/Constants";
import axios from "axios";
import { getPharmacyId } from "../../helpers/Utils";

export default class PMSExpiredItemsServices {
  static getExpiredItemsByPharmacyId(params) {
    return axios.get(`${URL.PMS_GET_EXPIRED_ITEMS}/${getPharmacyId()}`, {
      ...PMSExpiredItemsServices.getAuthHeader(),
      params,
    });
  }

  static getAuthHeader() {
    const accessToken = localStorage.getItem("oushodsheba_access_token");
    //console.log("accessToken: ", accessToken)
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
  }
}
